import { UseGzipCompression } from "@mpay/core/src/utilities/devToggles";

const pako = require("pako");

const gzipIdentifier = "gzip:";

const enableCompression = UseGzipCompression;

export function compressForCookie(input: string) {
  if (!enableCompression) {
    return input;
  }
  const asArray = new TextEncoder().encode(input);
  const deflated = pako.deflate(asArray).join("_");
  return gzipIdentifier + deflated;
}

export function decompressFromCookie(input: string) {
  const deflatedFromCookieAsArray = input.split("_");
  const reinflated = pako.inflate(deflatedFromCookieAsArray);
  const asText = new TextDecoder("utf-8").decode(reinflated);
  return asText;
}

export function decompressFromCookieOrDefault(input: string) {
  if (input === null || input === undefined) {
    return input;
  }

  if (!input.startsWith(gzipIdentifier)) {
    return input;
  }

  try {
    const deflatedFromCookieAsArray = input
      .slice(gzipIdentifier.length, Infinity)
      .split("_");

    // Simple heuristic to see if this actually looks like a gzipped value
    if (deflatedFromCookieAsArray.length < 2) {
      return input;
    }

    const reinflated = pako.inflate(deflatedFromCookieAsArray);
    const asText = new TextDecoder("utf-8").decode(reinflated);
    return asText;
  } catch (e) {
    return input;
  }
}
