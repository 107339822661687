import { normalizeRevealCode } from "@mpay/core/src/models/ticket/normalizeRevealCode";
import {
  compressForCookie,
  decompressFromCookieOrDefault,
} from "@mpay/web-tickets/src/utilities/gzip/gzip";
import { parseCookies, setCookie } from "nookies";
import { z } from "zod";

import {
  BasketSchemaVersion,
  BasketTickets,
  EmptyBasket,
  basketTicketsValidator,
} from "../../basket/basketTicketsValidator";

export const CUSTOMER_BASKET_COOKIE_NAME = "customer.basket";
export const STASHED_CUSTOMER_BASKET_COOKIE_NAME = "customer.basket.stashed";
export const RECENTLY_VIEWED_ORDER_SECRET_COOKIE_NAME =
  "customer.recently_viewed_order_secret";
export const recentlyViewOrderSecretCookieValidator = z
  .object({
    secret: z.string(),
    date: z.string(),
  })
  .optional();
export type RecentlyViewOrderSecretCookieValidator = z.infer<
  typeof recentlyViewOrderSecretCookieValidator
>;

export const CUSTOMER_TAG_ID_COOKIE_NAME = "customer.tagid";
export const SESSION_ID_COOKIE_NAME = "customer.sessionid";

/**
 * Lets you read the cookie synchronously in the browser.
 * Watch out for confusion between this and
 * `apps/web-tickets/app/(checkout)/s/[clientVanityUrl]/order/_lib/getBasketRealTickets.server.ts`
 * @returns
 */
export function getBasketStateFromCookie() {
  const cookies = parseCookies();
  const basketStateString = cookies[CUSTOMER_BASKET_COOKIE_NAME];
  return convertCookieStringToBasketState(
    decompressFromCookieOrDefault(basketStateString)
  );
}

export function setBasketStateCookie(basketState: BasketTickets) {
  try {
    basketTicketsValidator.parse(basketState);
    setCookie(
      null,
      CUSTOMER_BASKET_COOKIE_NAME,
      compressForCookie(JSON.stringify(basketState)),
      {
        path: "/",
        sameSite: "none",
        secure: true,
      }
    );
  } catch (e) {
    console.warn("Failed update", e);
  }
}

export function clearBasketStateCookie() {
  setBasketStateCookie(EmptyBasket);
}

type F = ReturnType<typeof getBasketStateFromCookie>;
export function updateBasketStateCookie(updater: (basket: F) => F) {
  const basket = getBasketStateFromCookie();
  const next = updater(basket);
  setBasketStateCookie(next);
}

export function convertCookieStringToBasketState(input: string): BasketTickets {
  try {
    const basket = basketTicketsValidator.parse(JSON.parse(input));
    return {
      __v: BasketSchemaVersion,
      basket: {
        ...basket.basket,
        revealCodes: basket.basket.revealCodes.map((code) => ({
          code: normalizeRevealCode(code.code),
        })),
      },
    };
  } catch {
    return EmptyBasket;
  }
}
