import { customerDiscountCode } from "@mpay/core/src/models/basket/customerDiscountCode";
import { z } from "zod";

export const ticketCartTicketValidator = z.object({
  basketTicketId: z.string(),
  ticketId: z.string(),
  eventId: z.string(),
  clientId: z.string(),
  isFreeTicket: z.boolean().default(false),
  discountCodeToApply: customerDiscountCode.nullish().default(null),
});
export type FTicketCartTicket = z.infer<typeof ticketCartTicketValidator>;
