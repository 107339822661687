import { z } from "zod";

export const revealCodeSchema = z
  .object({ code: z.string() })
  .or(z.string())
  .transform((x) => {
    // Handle previous basket case which was string only
    if (typeof x === "string") {
      return { code: x };
    }
    return x;
  });

export type RevealCode = z.infer<typeof revealCodeSchema>;
export type RevealCodeClient = RevealCode & { isPending: boolean };
