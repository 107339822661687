import cx from "classnames";
import * as React from "react";

import { twMerge } from "../../tailwind/tailwindMerge";
import { IconShim } from "./IconShim";
import type { ValidIcon } from "./IconShim";
import NotMobile from "./helpers/mobile/NotMobile";
import { BaseHeaderClasses } from "./typography/BaseHeaderClasses";
import { HeaderTagAuto } from "./typography/headers";

export type MessageType =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "error";

const messageClassnames: Record<
  string,
  { topColor: string; backgroundColor: string; textColor: string }
> = {
  warning: {
    topColor: "tw-border-orange-400",
    backgroundColor: "tw-bg-orange-50",
    textColor: "tw-text-orange-800",
  },
  info: {
    topColor: "tw-border-blue-400",
    backgroundColor: "tw-bg-blue-50",
    textColor: "tw-text-blue-800",
  },
  primary: {
    topColor: "tw-border-green-400",
    backgroundColor: "tw-bg-green-50",
    textColor: "tw-text-green-800",
  },
  secondary: {
    topColor: "tw-border-indigo-400",
    backgroundColor: "tw-bg-indigo-50",
    textColor: "tw-text-indigo-800",
  },
  success: {
    topColor: "tw-border-green-400",
    backgroundColor: "tw-bg-green-50",
    textColor: "tw-text-green-800",
  },
  danger: {
    topColor: "tw-border-red-400",
    backgroundColor: "tw-bg-red-50",
    textColor: "tw-text-red-800",
  },
  error: {
    topColor: "tw-border-red-400",
    backgroundColor: "tw-bg-red-50",
    textColor: "tw-text-red-800",
  },
};

type Align = "left" | "center" | "right";
const align: Record<Align, string> = {
  left: "tw-text-left",
  center: "tw-text-center",
  right: "tw-text-right",
};

type BreathingRoom = "none" | "small" | "medium";
const breathingRoomOuter: Record<BreathingRoom, string> = {
  none: "",
  small: "tw-py-2 tw-px-4",
  medium: "tw-py-6 tw-px-6",
};
const breathingRoomInner: Record<BreathingRoom, string> = {
  none: "",
  small: "tw-mb-0",
  medium: "tw-mb-4",
};

const breathingRoomMap = {
  breathingRoomOuter,
  breathingRoomInner,
};

type MessageProps = {
  type?: MessageType;
  header?: string | React.ReactNode;
  body?: string | React.ReactNode;
  icon?: ValidIcon;
  iconRight?: ValidIcon;
  iconSpin?: boolean;
  style?: React.CSSProperties;
  classes?: string;
  onClick?: any;
  breathingRoom?: BreathingRoom;
  alignHeader?: Align;
  isFullWidth?: boolean;
  hasHighlight?: boolean;
};
export function Message({
  type = "info",
  header,
  body,
  icon,
  iconRight,
  iconSpin,
  onClick,
  breathingRoom = "medium",
  alignHeader = "center",
  isFullWidth = false,
  hasHighlight = true,
}: MessageProps) {
  return (
    <article
      onClick={onClick}
      className={cx(
        breathingRoomMap.breathingRoomOuter[breathingRoom],
        "tw-rounded-lg tw-shadow",
        hasHighlight ? "tw-border-t-4" : "",
        messageClassnames[type].topColor,
        messageClassnames[type].backgroundColor,
        messageClassnames[type].textColor,
        isFullWidth && "tw-w-full"
      )}
    >
      <div className="tw-text-center">
        {header ? (
          <HeaderTagAuto
            className={twMerge(
              BaseHeaderClasses.h2,
              breathingRoomMap.breathingRoomInner[breathingRoom],
              align[alignHeader]
            )}
          >
            {header}
          </HeaderTagAuto>
        ) : null}
      </div>
      {body ? (
        <div className={"tw-flex tw-items-center tw-space-x-4"}>
          {icon && (
            <NotMobile>
              <div>
                <IconShim icon={icon} size={"lg"} spin={iconSpin} />
              </div>
            </NotMobile>
          )}
          <div className="tw-flex-grow">{body}</div>
          {iconRight && (
            <div>
              <IconShim right icon={iconRight} spin={iconSpin} />
            </div>
          )}
        </div>
      ) : null}
    </article>
  );
}

export function OrderStatusMessage({ type = "info", header }: MessageProps) {
  return (
    <article
      className={cx(
        "tw-py-4 tw-px-6",
        "tw-border-t-4 tw-rounded-t tw-shadow",
        messageClassnames[type].topColor,
        messageClassnames[type].backgroundColor,
        messageClassnames[type].textColor
      )}
    >
      <div className="tw-text-center">
        {header ? (
          <HeaderTagAuto className={BaseHeaderClasses.h2}>
            {header}
          </HeaderTagAuto>
        ) : null}
      </div>
    </article>
  );
}

export function ErrorMessage(props: MessageProps) {
  return (
    <Message
      header={"Something unexpected happened."}
      body={
        "An error occurred, but we don't have any more information about it. Please refresh the page and try again."
      }
      {...props}
      icon={"exclamation-triangle"}
      type={"danger"}
    />
  );
}

export default Message;
